<template>
	<header>
		<h1 class="title mr-3">
			{{ cams.title }}
		</h1>
	</header>
	<div class="controls">
		<div class="controls__row grid grid--2">
			<BaseSelect
				placeholder="Выберите плашкоут"
				:options="listShips"
				v-model:option="selectShipId"
				@update:option="updateCams"
			/>
		</div>
		<div class="controls__row grid grid--2">
			<BaseInput v-model="date.from" type="datetime-local" placeholder="от" @change="onChangeDate" />
			<BaseInput v-model="date.to" type="datetime-local" placeholder="до" @change="onChangeDate" />
		</div>
		<div class="controls__row">
			<input type="checkbox" id="only-events" v-model="isShowOnlyWithEvents"  @change="updateCams">
			<label for="only-events">
				показать события связанные с приемкой
			</label>
		</div>
	</div>
	<ul class="list">
		<li class="list-item" v-for="item in listCams" :key="item.id">
			<p class="list-item__date">
				<strong>
					{{ formattedDateTime(item.cameraDateTime) }}
				</strong>
			</p>
			<div class="list-item__info">
				<a :href="item.image" target="_blank">
					<img class="list-item__img" v-if="item.image" :src="item.image" :alt="item.cameraDateTime">
				</a>
			</div>
			<div class="list-item-actions">
				<ul class="list-item-actions__list" v-if="item.receptionPointActions.length">
					<li class="list-item-actions__item" v-for="action in item.receptionPointActions" :key="action.id">
						<p><strong>Сорт:</strong> {{ action.sort }}</p>
						<p><strong>Дата:</strong> {{ formattedDateTime(action.date) }}</p>
						<p><strong>Действие: </strong>{{ action.action }}</p>
						<p><strong>rfid:</strong> {{ action.rfid }}</p>
						<p><strong>Добытчик:</strong> {{ action.boardCompany }}</p>
						<p><strong>Вес:</strong> {{ action.weight }} кг</p>
						<p><strong>Исполнитель:</strong> {{ action.worker }}</p>
						<p><strong>Вид рыбы:</strong> {{ action.fishName }}</p>
					</li>
				</ul>
			</div>
		</li>
	</ul>
	<BasePagination
		v-model:value="pagination.page"
		:max="pagination.totalPages"
		@update:value="getCams"
	/>
</template>

<script>
import BOOKS from '@/modules/constants/books'
import BaseSelect from '../components/ui/BaseSelect'
import BaseInput from '../components/ui/BaseInput'
import BasePagination from '../components/ui/BasePagination'

import { onMounted, reactive, ref, computed } from 'vue'
import { useApi } from '@/modules/api'
import formattedDateTime from '@/utils/formattedDateTime'

export default {
	name: 'Cams',
	components: { BaseSelect, BaseInput, BasePagination },
	setup () {
		const { cams, ships } = BOOKS
		const listCams = ref([])
		const listShips = ref({})
		const selectShipId = ref(null)
		const isShowOnlyWithEvents = ref(false)
		const date = ref({
			from: null,
			to: null
		})
		const pagination = reactive({
			show: true,
			page: 0,
			size: 10,
			totalPages: 0
		})

		const setImages = (data) => {
			listCams.value = []

			data.forEach(item => {
				item.image = null
				listCams.value.push(item)
			})

			listCams.value.forEach(item => {
				item.image = `api${item.link}`
			})
		}

		const getShips = async () => {
			const { get, data } = useApi(ships.endpoint)
			await get()

			data.value.content.forEach(item => {
				listShips.value[item.id] = item.name
			})
		}

		const getCams = async () => {
			const { get, data } = useApi(cams.endpoint)
			let urlParametrs = `?offset=0&page=${pagination.page}&size=${pagination.size}&sort=cameraDateTime,desc`
			urlParametrs = selectShipId.value ? urlParametrs + `&receptionPointId=${selectShipId.value}` : urlParametrs
			if (selectShipId.value) {
				urlParametrs = urlParametrs + `&receptionPointId=${selectShipId.value}`
			}
			if (isShowOnlyWithEvents.value) {
				urlParametrs = urlParametrs + `&showOnlyWithEvents=${isShowOnlyWithEvents.value}`
			}
			if (isDateComplete.value) {
				urlParametrs = urlParametrs + `&from=${date.value.from}&to=${date.value.to}`
			}

			await get(urlParametrs)

			const { content, totalPages } = data.value
			pagination.totalPages = totalPages

			setImages(content)
		}

		const init = async () => {
			await getShips()
			await getCams()
		}

		const updateCams = () => {
			pagination.page = 0
			pagination.totalPages = 0
			getCams()
		}

		const onChangeDate = () => {
			if (date.value.from > date.value.to) {
				date.value.to = null
			}
			updateCams()
		}

		const isDateComplete = computed(() =>
			date.value.from && date.value.to && date.value.from <= date.value.to)

		onMounted(async () => {
			await init()
		})

		return {
			cams,
			listCams,
			listShips,
			pagination,
			selectShipId,
			isShowOnlyWithEvents,
			date,
			getCams,
			onChangeDate,
			updateCams,
			formattedDateTime
		}
	}
}
</script>

<style lang="scss" scoped>
header {
	display: flex;
	align-items: center;
	justify-content: space-between;

	label {
		margin-left: 4px;
	}
}

.controls {
	margin-top: 20px;

	&__row {
		&:not(:first-child) {
			margin-top: 10px;
		}
	}
}

.list {
	margin-top: 20px;
	list-style: none;
}

.list-item {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;

	&__date {
		width: 100%;
		margin: 10px 0 5px;
	}

	&__info {
		width: 60%;
	}

	&__img {
		width: 100%;
		border: 0;
		border-radius: 8px;
		outline: none;
	}
}

.list-item-actions {
	position: relative;
	width: 40%;
	overflow-y: auto;

	&__list {
		position: absolute;
		top: 0;
		left: 0;
		padding-left: 20px;
		list-style: none;
	}

	&__item {
		&:not(:first-child) {
			margin-top: 10px;
		}
	}
}

</style>
